<template>
  <PageSection
    v-loading="isLoading"
    class="Members"
    :page-tooltip="$t('pages.settings.brand.members.tooltip')"
    :page-title="$t('components.brand_management.current_brand_members')"
  >
    <div class="field m-b-m">
      <label class="label">{{ $t('components.brand_management.invite_new_members') }}</label>

      <InviteUsers
        :members="members"
        @invited="fetchData"
        @resend="fetchPendingInvitations"
      />
    </div>

    <VSeparator class="m-v-l" />

    <div v-if="invitations.length" class="m-b-m">
      <Heading :serif="false" size="4" weight="bold">
        {{ $t('pages.settings.brand.members.pending_members') }}
      </Heading>

      <PendingMembersTable
        v-loading="isFetchingInvitations"
        :brand-id="activeBrandId"
        :invitations.sync="invitations"
      />
    </div>

    <div class="field m-b-m">
      <Heading :serif="false" size="4" weight="bold">
        {{ $t('pages.settings.brand.members.members') }}
      </Heading>

      <MembersTable
        :brand-id="activeBrandId"
        :members.sync="members"
      />
    </div>
  </PageSection>
</template>

<script>
import { BrandApiService } from '@hypefactors/shared/js/services/api/BrandApiService.js'

import Heading from '@hypefactors/shared/js/components/core/Heading.vue'
import VSeparator from '@hypefactors/shared/js/components/core/VSeparator.vue'

import PageSection from '@/components/core/PageSection.vue'
import InviteUsers from '@/pages/settings/components/InviteUsers.vue'
import MembersTable from '@/pages/settings/components/MembersTable.vue'
import PendingMembersTable from '@/pages/settings/components/PendingMembersTable.vue'

export default {
  components: {
    PageSection,
    Heading,
    VSeparator,
    PendingMembersTable,
    MembersTable,
    InviteUsers
  },

  data () {
    return {
      isLoading: false,
      isFetchingInvitations: false,
      members: [],
      invitations: []
    }
  },

  watch: {
    activeBrandId: {
      immediate: true,
      handler: 'fetchData'
    }
  },

  methods: {
    async fetchData () {
      this.isLoading = true

      await Promise.all([this.fetchMembers(), this.fetchPendingInvitations()])

      this.isLoading = false
    },

    fetchMembers () {
      return BrandApiService.fetchMembers(this.activeBrandId)
        .then((members) => {
          this.members = members
        })
        .catch((error) => {
          this.$displayRequestError(error)
        })
    },

    fetchPendingInvitations () {
      this.isFetchingInvitations = true

      return BrandApiService.fetchInvitations(this.activeBrandId, { params: { accepted: false } })
        .then((invitations) => {
          this.invitations = invitations
        })
        .catch((error) => {
          this.$displayRequestError(error)
        })
        .finally(() => {
          this.isFetchingInvitations = false
        })
    }
  }
}
</script>
